import { makeStyles } from "@material-ui/core";

const SubSucessStyles = makeStyles({
  mainContainer: {
    display: "grid",
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center",
    marginTop: 40,
  },
  root: {
    flexDirection: "column",
    display: "flex",
    flexGrow: 1,
    height: "100%",
    justifyContent: "space-between",
  },
});

export default SubSucessStyles;
