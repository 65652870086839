import { makeStyles } from "@material-ui/core";
import { optionProps } from "../types";

const pricingStyles = makeStyles({
  root: {
    textAlign: "center",
    marginTop: 120,
    display: "grid",
    justifyContent: "center",
    alignContent: "center",
    
  },

  tabNavigator: {
    justifySelf: "center",
    "& .MuiTab-root": {
      textTransform: "none",
      fontSize: 16,
    },
  },

  grid: {
    flexDirection: "row",
    marginTop: 100,
    justifyContent: "center",
  },

  mainTitle: {
    fontFamily: "Montserrat",
    fontSize: 32,
    fontWeight: 700,
    lineHeight: 1.25,
    color: "#0C344B",
  },

  clasicOffer: {
    maxWidth: 400,
    "& .MuiCard-root": {
      borderRadius: 12,
      padding: 30,
      "& .MuiDivider-root": {
        marginRight: 30,
        marginLeft: 30,
        margin: 45,
        justifyContent: "center",
      },
      "& .MuiButton-root": {
        backgroundColor: "#0C344B",
        color: "white",
        width: 280,
        margin: 20,
        fontWeight: "bold",
      },
    },
  },

  price: {
    flexDirection: "row",
    justifyContent: "center",
    display: "inline-flex",
    margin: 0,
    "& p": {
      fontSize: 20,
      fontWeight: 600,
      color: "#0C344B",
      marginLeft: 15,
    },
  },

  customOffer: {
    maxWidth: 400,
    "& .MuiCard-root": {
      borderRadius: 12,
      padding: 30,
      backgroundColor: "#0c344b",
      "& p": {
        color: "white",
      },
      "& .MuiDivider-root": {
        marginRight: 30,
        marginLeft: 30,
        margin: 45,
        justifyContent: "center",
        background: "grey",
      },
      "& .MuiButton-root": {
        color: "#0C344B",
        background: "white",
        width: 280,
        margin: 20,
        fontWeight: "bold",
      },
    },
  },

  title: {
    fontSize: 20,
    fontWeight: 600,
    color: "#0C344B",
  },
  subtitle: { fontSize: 20, color: "#0C344B", padding: 40 },
});

const optionStyle = makeStyles({
  option: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
    alignContent: "center",
    "& p": {
      fontSize: 16,
      marginLeft: 20,
      lignHeight: 1.4,
      color: "#0C344B",
      margin: 6,
      fontWeight: 600,
    },
    "& span": {
      height: 10,
      width: 10,
      backgroundColor: (props: optionProps) => props.color,
      borderRadius: "50%",
      display: "inline-block",
    },
  },
});

export { pricingStyles, optionStyle };
