import React from "react";
import { FiSend } from "react-icons/fi";
import { TiClipboard } from "react-icons/ti";
import { IoIosFolder } from "react-icons/io";

import homePageStyle from "../styles/HomePage.style";
import { NavBar, Footer, MainBanner, Download, CheckLine } from "../Components";
import { Grid, Icon, Hidden } from "@material-ui/core";
import screenShot1 from "../assets/screenshot-1.png";
import Pricing from "../Components/Prycing";
import { connect } from "react-redux";
import { state } from "../types/state";
import { useTranslation } from "react-i18next";

const HomePage = () => {
  let props = {
    diagonalHeight: window.innerWidth / 1.5,
  };

  const classes = homePageStyle(props);
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <header className={classes.header}>
        <div className={classes.diagonalTop}></div>
        <div className={classes.container}>
          <NavBar />
          <MainBanner />
        </div>
      </header>
      <div className={classes.container}>
        <Download />

        <Grid container spacing={4} className={classes.grids}>
          <Grid className={classes.appAdvantage} item sm>
            <span>
              <Icon component={TiClipboard}></Icon>
              <p>{t("Inspection and observation")}</p>
            </span>
            <p>{t("observation-description")}</p>
          </Grid>
          <Grid className={classes.appAdvantage} item sm>
            <span>
              <Icon component={FiSend}></Icon>
              <p>{t("Report and Communication")}</p>
            </span>
            <p>{t("report-description")}</p>
          </Grid>
          <Grid className={classes.appAdvantage} item sm>
            <span>
              <Icon component={IoIosFolder}></Icon>
              <p>{t("organize-project")}</p>
            </span>
            <p>{t("organize-project-description")}</p>
          </Grid>
        </Grid>
        <div className={classes.worksiteFollow} id="about">
          <span>
            <h4>{t("precise-notes")}</h4>
            <p>{t("precise-notes-description")}</p>
            <CheckLine text={t("Track your jobsites simply and intuitively")} />
            <CheckLine
              text={t("Reservations, remarks, reports, speakers, documents")}
            />
          </span>
          <Hidden smDown={true}>
            <img
              src={screenShot1}
              className={classes.screnShot1}
              alt="screenShot1"
            ></img>
          </Hidden>
        </div>
        <Pricing />
      </div>
      <span className={classes.footer}>
        <Footer />
      </span>
    </div>
  );
};

const mapStateToProps = (state: state) => {
  return {
    isFetching: state.authState.isFetching,
  };
};

export default connect(mapStateToProps, null)(HomePage);
