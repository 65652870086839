import React, { useState, useEffect, Fragment } from "react";
import { UserPageStyle } from "../styles/User.style";
import {
  Card,
  Avatar,
  Divider,
  Button,
  CircularProgress,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Drawer,
  ListItemIcon,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { IoMdCheckmark } from "react-icons/io";
import { useForm } from "react-hook-form";
import { state } from "../types/state";
import { IoMdArrowBack } from "react-icons/io";
import "react-credit-cards/es/styles-compiled.css";
import MenuIcon from "@material-ui/icons/Menu";
import { auth } from "../utils/Firebase";
import { MdKeyboardArrowRight } from "react-icons/md";
import { signOut, editEmail } from "../redux/actions";
import { useTranslation } from "react-i18next";
import { SubscriptionDashboard } from "../Components/SubscriptionDash";
import useWindowDimensions from "../utils/hooks/useWindowDimension";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CardMembershipIcon from "@material-ui/icons/CardMembership";
import { useContext } from "react";
import { AuthContext } from "../Components/AuthProvider";

export const UserPage = () => {
  const classes = UserPageStyle();
  const isFetching = useSelector((state: state) => state.authState.isFetching);
  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();
  const [newEmail, setNewEmail] = useState("");
  const [showDrawer, setShowDrawer] = useState(false);
  const rank = useSelector((state: state) => state.authState.rank);
  const dispatch = useDispatch();
  const subscribed = useSelector((state: state) => state.authState.rank === 1);
  const { width } = useWindowDimensions();
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setNewEmail(user.email);
      }
    });
  }, [dispatch, rank, subscribed]);

  const onSubmit = (data: Record<string, any>) => {
    dispatch(editEmail(data.email));
  };

  const subscription = () => {
    return <SubscriptionDashboard />;
  };

  const acount = () => {
    return (
      <div className={classes.profileContainer}>
        <div className={classes.profile}>
          <Avatar className={classes.avatar}>
            {authContext.user.displayName[0]}
          </Avatar>
          <p className={classes.nameField}>{authContext.user.displayName}</p>
          <Divider />
          <p className={classes.fieldTitle}>{t("Email")} :</p>
          <form
            className={classes.textFieldSpan}
            onSubmit={handleSubmit(onSubmit)}
          >
            <span style={{ textAlign: "left" }}>
              <TextField
                value={authContext.user.email}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setNewEmail(event.target.value);
                }}
                error={errors.email != null}
                variant="outlined"
                name="email"
                fullWidth={true}
                label={t("Email")}
                type="email"
                inputRef={register({
                  required: `${t("This field is required")}`,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: `${t("This is not a valid email")}`,
                  },
                })}
              />
              {errors.email != null && (
                <p className={classes.errorMessage}>
                  {errors.email && errors.email.message}
                </p>
              )}
            </span>

            {isFetching === false && newEmail !== authContext.user.email ? (
              <IconButton type="submit">
                <IoMdCheckmark />
              </IconButton>
            ) : (
              <span />
            )}
            {isFetching && <CircularProgress />}
          </form>
          <span className={classes.passwordSpan}>
            <p>{t("Password")} :</p>
            <TextField value="password" type="password" variant="outlined" />
          </span>
        </div>
      </div>
    );
  };

  const getSelectedPage = () => {
    switch (index) {
      case 0:
        return acount();
      case 1:
        return subscription();
      default:
        break;
    }
  };
  const getHomeButton = () => {
    return (
      <Button
        className={classes.goHome}
        onClick={() => {
          history.push("/");
        }}
      >
        <span>
          <IoMdArrowBack color="#1e88e5" />
          <p>{t("Go home")}</p>
        </span>
      </Button>
    );
  };
  return (
    <Fragment>
      {authContext.authenticated ? (
        <div className={classes.root}>
          {width > 600 ? getHomeButton() : null}

          {authContext.loadingAuthState ? (
            <CircularProgress />
          ) : width > 600 ? (
            <Card className={classes.card}>
              <List className={classes.list}>
                <div>
                  {" "}
                  <ListItem
                    button
                    onClick={() => {
                      setIndex(0);
                    }}
                  >
                    <ListItemText primary={t("Account")} />
                    <ListItemSecondaryAction>
                      <MdKeyboardArrowRight />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem
                    button
                    onClick={() => {
                      setIndex(1);
                    }}
                  >
                    <ListItemText primary={t("Subscription")} />
                    <ListItemSecondaryAction>
                      <MdKeyboardArrowRight />
                    </ListItemSecondaryAction>
                  </ListItem>
                </div>

                <Button
                  className={classes.logOutButton}
                  onClick={() => dispatch(signOut())}
                >
                  {isFetching ? <CircularProgress /> : t("Disconnect")}
                </Button>
              </List>
              <Divider orientation="vertical" />
              {getSelectedPage()}
            </Card>
          ) : (
            <Card>
              <span className={classes.drawerSpan}>
                <IconButton
                  onClick={() => {
                    setShowDrawer(true);
                  }}
                >
                  <MenuIcon />
                </IconButton>
              </span>

              {getSelectedPage()}
              <Drawer open={showDrawer} onClose={() => setShowDrawer(false)}>
                {[
                  getHomeButton(),
                  <ListItem
                    style={{ marginTop: 100 }}
                    button
                    onClick={() => {
                      setIndex(0);
                      setShowDrawer(false);
                    }}
                  >
                    <ListItemIcon>
                      <AccountCircleIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("My Account")} />
                  </ListItem>,
                  <Divider />,
                  <ListItem
                    button
                    onClick={() => {
                      setIndex(1);
                      setShowDrawer(false);
                    }}
                  >
                    <ListItemIcon>
                      <CardMembershipIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("Subscription")} />
                  </ListItem>,
                  <Divider />,
                  <Button
                    className={classes.logOutButton}
                    onClick={() => dispatch(signOut())}
                  >
                    {isFetching ? <CircularProgress /> : t("Disconnect")}
                  </Button>,
                ]}
              </Drawer>
            </Card>
          )}
        </div>
      ) : (
        <Redirect to="/" />
      )}
    </Fragment>
  );
};
