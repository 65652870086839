import React from "react";
import Button from "@material-ui/core/Button";
import SignUpFormStyle from "../../styles/SignUpForm.style";
import { TextField, CircularProgress } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { signup } from "../../redux/actions";
import { state } from "../../types/state";
import { useTranslation } from "react-i18next";

export const SignUpForm = () => {
  const classes = SignUpFormStyle();
  const { register, handleSubmit, errors } = useForm();
  const isFetcing = useSelector((state: state) => state.authState.isFetching);
  const error = useSelector((state: state) => state.authState.error);
  const dispatch = useDispatch();

  const onSubmit = (data: any) => {
    dispatch(
      signup(data.email, data.password, data.firstName + " " + data.lastName)
    );
  };
  const { t } = useTranslation();

  return (
    <div>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label={t("Name")}
          name="firstName"
          inputRef={register({
            required: `${t("This field is required")}`,
            validate: (value) => typeof value === "string",
          })}
          variant="outlined"
        />
        <p className={classes.error}>
          {errors.firstName && errors.firstName.message}
        </p>

        <TextField
          label={t("Last name")}
          variant="outlined"
          name="lastName"
          inputRef={register({
            required: `${t("This field is required")}`,
            validate: (value) => typeof value === "string",
          })}
        />
        <p className={classes.error}>
          {errors.lastName && errors.lastName.message}
        </p>
        <TextField
          label={t("Email")}
          name="email"
          error={errors.email != null}
          type="email"
          inputRef={register({
            required: `${t("This field is required")}`,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: `${t("This is not a valid email")}`,
            },
          })}
          variant="outlined"
        />
        <p className={classes.error}>{errors.email && errors.email.message}</p>

        <TextField
          label={t("Password")}
          hidden={true}
          name="password"
          type="password"
          variant="outlined"
          inputRef={register({
            required: `${t("This field is required")}`,
            minLength: 6,
          })}
        />
        <p className={classes.error}>
          {errors.password && errors.password.message}
        </p>

        <Button variant="contained" type="submit">
          {isFetcing ? <CircularProgress /> : t("Create")}
        </Button>
      </form>
      {error != null ? <p>{t("An error occured")}</p> : null}
    </div>
  );
};
