const GET_PAYMENT_METHODS = "GET_PAYMENT_METHODS";
const IS_FETCHING = "IS_FECHING";

const SUBSCRIBE = "SUBSCRIBE";

const GET_SUBSCRIPTION = "GET_SUBSCRIPTION";
const GET_SUBCRIPTION_ERROR = "GET_SUBCRIPTION_ERROR";

const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";
const CANCEL_SUBSCRIPTION_ERROR = "CANCEL_SUBSCRIPTION_ERROR";
const CANCEL_SUBSCRIPTION_ISFETCHING = "CANCEL_SUBSCRIPTION_ERROR";

const UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD";
const UPDATE_PAYMENT_METHOD_ISFETCHING = "UPDATE_PAYMENT_METHOD_ISFETCHING";
const UPDATE_PAYMENT_METHOD_ERROR = "UPDATE_PAYMENT_METHOD_ERROR";

const ERROR = "ERROR";

const GET_PLAN_DETAIL = "GET_PLAN_DETAIL";

export {
  GET_PAYMENT_METHODS,
  IS_FETCHING,
  SUBSCRIBE,
  GET_SUBSCRIPTION,
  ERROR,
  CANCEL_SUBSCRIPTION,
  GET_PLAN_DETAIL,
  GET_SUBCRIPTION_ERROR,
  CANCEL_SUBSCRIPTION_ISFETCHING,
  CANCEL_SUBSCRIPTION_ERROR,
  UPDATE_PAYMENT_METHOD,
  UPDATE_PAYMENT_METHOD_ISFETCHING,
  UPDATE_PAYMENT_METHOD_ERROR,
};
