import React from "react";
import {  Footer } from "../Components";
import pageNotFoundStyle from "../styles/404.style";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";

const PageNotFound = () => {
  const classes = pageNotFoundStyle();
  const history = useHistory();
  return (
    <div className={classes.root}>
      <span className={classes.error}>
        <h1>Error ...</h1>
        <p className={classes.descriptuon}>
          Oops, something went wrong! This is embarrassing. <br />
          Try heading back to the homepage or reach out to us if you need help.
        </p>
        <Button
          className={classes.goHomeButton}
          onClick={() => {
            history.push("/");
          }}
        >
          Go home
        </Button>
      </span>

      <Footer />
    </div>
  );
};

export default PageNotFound;
