import React, { useEffect } from "react";
import { useState } from "react";
import Confetti from "react-dom-confetti";

import { Footer } from "../Components";
import SubSucessStyles from "../styles/SubSucess.style";
import logoSucess from "../assets/confetti.svg"; //
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
const SubSucess = () => {
  const classes = SubSucessStyles();
  const history = useHistory();
  const [showConfettis, setShowConfettis] = useState(false);
  const configConfetti = {
    angle: 90,
    spread: 360,
    startVelocity: 40,
    elementCount: 120,
    dragFriction: 0.12,
    duration: 3000,
    stagger: 3,
    width: "10px",
    height: "10px",
    perspective: "500px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  };
  const { t } = useTranslation();
  useEffect(() => {
    setShowConfettis(true);
  }, [showConfettis]);

  return (
    <div className={classes.root}>
      <div className={classes.mainContainer}>
        {" "}
        <Confetti active={showConfettis} config={configConfetti} />
        <img
          src={logoSucess}
          alt="Confetti"
          style={{ justifySelf: "center" }}
          height={300}
        />
        <h3>{t("sub")}</h3>
        <h4>{t("Please restart your app to get your license activated")}</h4>
        <Button
          color="primary"
          onClick={() => {
            history.push("/");
          }}
        >
          {t("Go home")}
        </Button>
      </div>
      <Footer />
    </div>
  );
};

export default SubSucess;
