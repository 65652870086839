import { paymentActionType } from "../../types/payment";
import {
  IS_FETCHING,
  SUBSCRIBE,
  GET_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION,
  GET_PLAN_DETAIL,
  ERROR,
  GET_SUBCRIPTION_ERROR,
  CANCEL_SUBSCRIPTION_ISFETCHING,
  CANCEL_SUBSCRIPTION_ERROR,
  UPDATE_PAYMENT_METHOD_ISFETCHING,
  UPDATE_PAYMENT_METHOD_ERROR,
  UPDATE_PAYMENT_METHOD,
} from "../types/payment.type";
import { state } from "../../types/state";

const initialState: state["paymentState"] = {
  isFetching: false,
  subscribed: false,
  subscription: null,
  error: null,
  planDetails: null,
  cancelSubIsFetching: false,
  updatePaymentMethodIsFetching: false,
};

const paymentReducer = (
  state = initialState,
  action: paymentActionType
): state["paymentState"] => {
  switch (action.type) {
    case IS_FETCHING: {
      return {
        ...state,
        isFetching: action.payload,
        error: action.payload ? null : state.error,
      };
    }
    case SUBSCRIBE: {
      return {
        ...state,
        subscribed: action.payload,
      };
    }
    case GET_SUBSCRIPTION: {
      return {
        ...state,
        subscription: action.payload,
        subscribed: true,
      };
    }
    case GET_SUBCRIPTION_ERROR: {
      return {
        ...state,
        subscribed: false,
        subscription: null,
      };
    }
    case CANCEL_SUBSCRIPTION: {
      return {
        ...state,
        subscribed: false,
        subscription: null,
        isFetching: false,
      };
    }
    case UPDATE_PAYMENT_METHOD_ISFETCHING: {
      return { ...state, updatePaymentMethodIsFetching: action.payload };
    }
    case UPDATE_PAYMENT_METHOD_ERROR: {
      return { ...state, error: action.payload };
    }
    case UPDATE_PAYMENT_METHOD: {
      const newSubscription = state.subscription;
      newSubscription.credidCard = action.payload;

      return { ...state, subscription: newSubscription };
    }
    case CANCEL_SUBSCRIPTION_ERROR: {
      return {
        ...state,
        subscription: null,
      };
    }
    case CANCEL_SUBSCRIPTION_ISFETCHING: {
      return {
        ...state,
        cancelSubIsFetching: action.payload,
      };
    }
    case GET_PLAN_DETAIL: {
      return {
        ...state,
        planDetails: {
          currency: action.payload.currency,
          name: action.payload.name,
          interval: action.payload.interval,
          amount: action.payload.amount,
        },
      };
    }
    case ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
    default:
      return state;
  }
};

export default paymentReducer;
