import firebase from "firebase";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getUserData } from "../redux/actions";
type ContextProps = {
  user: firebase.User | null;
  authenticated: boolean;
  setUser: any;
  loadingAuthState: boolean;
};
export const AuthContext = React.createContext<Partial<ContextProps>>({});
export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState(null as firebase.User | null);
  const dispatch = useDispatch();
  const [loadingAuthState, setLoadingAuthState] = useState(true);
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user: any) => {
      setUser(user);
      setLoadingAuthState(false);
      if (user) {
        dispatch(getUserData(user));
      }
    });
  }, [dispatch]);
  return (
    <AuthContext.Provider
      value={{
        user,
        authenticated: user !== null,
        setUser,
        loadingAuthState,
      }}
    >
      {" "}
      {children}{" "}
    </AuthContext.Provider>
  );
};
