import { makeStyles } from "@material-ui/core";

const mainBannerStyle = makeStyles({
  tryButton: {
    background: "#0C344B",
    color: "white",
    height: 40,
    marginTop: 16,
    "& :hover": {
      color: "#0C344B"
    }
  },

  mainTitle: {
    alignItems: "center",
    maxWidth: 500,
    "& h1": {
      justifyContent: "center",
      color: "#082333",
      fontWeight: "bold",
      fontSize: 40
    },
    "& p": {
      fontSize: 20
    }
  },
  root: {
    paddingTop: 30,
    flexDirection: "row",
    display: "flex",
    placeContent: "start space-evenly"
  },
  worksiteImg: {
    maxHeight: 400,

    alignSelf: "center",
    borderRadius: 10
  }
});

export default mainBannerStyle;
