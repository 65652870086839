import { makeStyles } from "@material-ui/core";

const LogInStyle = makeStyles({
  root: {
    background: "#EFF3F5",
    flexDirection: "column",
    display: "grid",
    justifyContent: "center",
    alignContent: "center",
    height: "100%",
  },

  icon: {
    maxHeight: 250,
    justifySelf: "center",
  },
  appIcon: {
    justifySelf: "center",
  },

  tabNavigator: {
    "& .MuiTab-root": {
      textTransform: "none",
      color: "#082333",
    },
  },

  body: {
    height: "100%",
    width: "100%",
    background: "#f6f7fb",
  },
});

export default LogInStyle;
