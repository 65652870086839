import React from "react";
import checkLineStyle from "../styles/CheckLine.style";
import { ReactComponent as CheckIcon } from "../assets/check-greeen.svg";

interface CheckLineProps {
  text: String;
}

export const CheckLine = (props: CheckLineProps) => {
  const classes = checkLineStyle();
  return (
    <span className={classes.root}>
      <CheckIcon className={classes.icon} />
      <p>{props.text}</p>
    </span>
  );
};
