import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Drawer,
  IconButton,
  useMediaQuery,
} from "@material-ui/core";
import { ReactComponent as Logo } from "../assets/icon.svg";
import navBarStyles from "../styles/NavBar.style";
import { useHistory } from "react-router-dom";
import { IoIosMenu } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { AuthContext } from "./AuthProvider";

export const NavBar = () => {
  const classes = navBarStyles();
  let history = useHistory();
  const { t } = useTranslation();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const isDesktop = useMediaQuery("(min-width:550px)");
  const authContext = useContext(AuthContext);
  const goHome = () => {
    history.push("/");
  };

  const toolBar = (style: any) => {
    return (
      <span className={style}>
        <span>
          <Button
            onClick={async () => {
              document
                .getElementById("about")
                .scrollIntoView({ behavior: "smooth" });
              setDrawerOpen(false);
            }}
          >
            {t("About")}
          </Button>
          <Button
            onClick={async () => {
              document
                .getElementById("pricing")
                .scrollIntoView({ behavior: "smooth" });
            }}
          >
            {t("Pricing")}
          </Button>
          <Button
            onClick={async () => {
              document
                .getElementById("download")
                .scrollIntoView({ behavior: "smooth" });
            }}
          >
            {t("Download")}
          </Button>
        </span>
        <span>
          {authContext.authenticated ? (
            <Button
              className={classes.signIn}
              onClick={() => {
                history.push(`/myaccount`);
              }}
            >
              {t("My Account")}
            </Button>
          ) : (
            <span>
              <Button
                className={classes.signIn}
                onClick={() => {
                  history.push({
                    pathname: "/login",
                    state: { isSignIn: true },
                  });
                }}
              >
                {t("Log in")}
              </Button>
              <Button
                className={classes.signUp}
                onClick={() => {
                  history.push({
                    pathname: "/login",
                    state: { isSignUP: true },
                  });
                }}
                variant="outlined"
              >
                {t("Sign Up")}
              </Button>
            </span>
          )}
        </span>
      </span>
    );
  };

  return (
    <AppBar position="static" className={classes.navBar}>
      <Toolbar>
        <span className={classes.menu}>
          <Button onClick={goHome}>
            <Logo />
          </Button>

          {isDesktop ? (
            toolBar(classes.toolbar)
          ) : (
            <span>
              <IconButton
                className={classes.drawerButton}
                onClick={() => {
                  setDrawerOpen(!isDrawerOpen);
                }}
              >
                <IoIosMenu />
              </IconButton>
              <Drawer
                anchor="top"
                open={isDrawerOpen}
                onClose={() => {
                  setDrawerOpen(false);
                }}
              >
                {toolBar(classes.drawer)}
              </Drawer>
            </span>
          )}
        </span>
      </Toolbar>
    </AppBar>
  );
};
