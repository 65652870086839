import React, { useState } from "react";
import {
  TextField,
  Button,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import SignInFormStyle from "../../styles/SignInForm.style";
import { SignInProps } from "../../types";
import { useForm } from "react-hook-form";
import GoogleIcon from "../../assets/google-icon.png";
import FacebookIcon from "../../assets/facebook.png";
import { useSelector, useDispatch } from "react-redux";
import { state } from "../../types/state";
import ErrorMessage from "../Error";
import { useTranslation } from "react-i18next";
import { forgotPassword } from "../../redux/actions";

export const SignInForm = (props: SignInProps) => {
  const classes = SignInFormStyle();
  const { register, handleSubmit, errors, triggerValidation } = useForm();
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const signInError = useSelector(
    (state: state) => state.authState.signInError
  );
  const passwordReset = useSelector(
    (state: state) => state.authState.passwordReset
  );

  const onSubmit = (data: any) => {
    props.onSubmit(data.email, data.password);
  };
  const { t } = useTranslation();
  return (
    <div>
      <span className={classes.icons}>
        <IconButton onClick={props.onGoogleSubmit}>
          <img src={GoogleIcon} alt="googleIcon" />
        </IconButton>
        <IconButton onClick={props.onFacebookSubmit}>
          <img src={FacebookIcon} alt="facebookIcon" />
        </IconButton>
      </span>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label={t("Email")}
          name="email"
          error={errors.email != null}
          type="email"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          inputRef={register({
            required: `${t("This field is required")}`,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i,
              message: `${t("This is not a valid email")}`,
            },
          })}
          variant="outlined"
        />
        <p className={classes.error}>{errors.email && errors.email.message}</p>
        <TextField
          name="password"
          type="password"
          error={errors.password != null}
          label={t("Password")}
          variant="outlined"
          inputRef={register({ required: `${t("This field is required")}` })}
        />
        {errors.password != null && errors.password.message != null ? (
          <p className={classes.error}>
            {errors.password && errors.password.message}
          </p>
        ) : null}

        {passwordReset ? (
          <p>An email to reset your password has been sent to you.</p>
        ) : (
          <Button
            className={classes.forgotPassword}
            color="primary"
            onClick={async () => {
              let validate = await triggerValidation("email");
              if (validate) {
                dispatch(forgotPassword(email));
              }
            }}
          >
            {t("Forgot password")}?
          </Button>
        )}
        <Button
          variant="contained"
          className={classes.submitbutton}
          disabled={props.isFetching}
          type="submit"
        >
          {props.isFetching ? <CircularProgress /> : t("Sign in")}
        </Button>
      </form>
      {signInError != null ? (
        <ErrorMessage error={t("An error occured")} />
      ) : null}
    </div>
  );
};
