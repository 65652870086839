const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";

const AUTH_ERROR = "AUTH_ERROR";

const EDIT_EMAIL = "EDIT_EMAIL";

const SIGN_OUT = "SIGN_OUT";
const GET_USER_DATA = "GET_USER_DATA";

const IS_FETCHING = "IS_FETCHING";

const SIGN_IN_ERROR = "SIGN_IN_ERROR";

const AUTH_STATE_CHANGE = "AUTH_STATE_CHANGE";

const FORGOT_PASSWORD = "FORGOT_PASSWORD";

export {
  AUTH_ERROR,
  SIGN_UP_SUCCESS,
  IS_FETCHING,
  SIGN_OUT,
  EDIT_EMAIL,
  AUTH_STATE_CHANGE,
  GET_USER_DATA,
  FORGOT_PASSWORD,
  SIGN_IN_ERROR,
};
