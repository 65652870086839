import { makeStyles } from "@material-ui/core";

const SignUpFormStyle = makeStyles({
  form: {
    display: "grid",
    marginTop: 25,
    alignContent: "center",
    "& .MuiTextField-root": {
      background: "#fff",
    },
    "& .MuiButton-root": {
      margin: 20,
      padding: 10,
      marginTop: 30,
      background: "#082333",
      color: "white",
      width: 100,
      justifySelf: "center",
      borderRadius: 10,
    },
  },

  error: {
    color: "red",
    fontSize: 12,
    marginTop: 3,
  },
});
export default SignUpFormStyle;
