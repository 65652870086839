import { makeStyles } from "@material-ui/core";

const pageNotFoundStyle = makeStyles({
  root: {
    maxWidth: 1200,
    marginRight: "auto",
    marginLeft: "auto",
    display: "grid",
    height: "100%",
  },
  error: {
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    display: "grid",
  },
  descriptuon: {
    fontSize: 20,
  },
  goHomeButton: {
    color: "#1976d2",
    width: 100,
    justifySelf: "center",
    paddingTop: 20,
    fontSize: 16,
  },
});

export default pageNotFoundStyle;
