import { makeStyles } from "@material-ui/core";

const navBarStyles = makeStyles((theme) => ({
  navBar: {
    backgroundColor: "transparent",
    boxShadow: "none",
    padding: 0,
    "& .MuiToolbar-gutters": {
      padding: 0,
    },
  },

  menu: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "space-between",
    "& Button": {
      fontSize: 16,
      textTransform: "none",
      color: "#0c344b",
    },
  },

  drawerButton: {
    display: "block",
    justifySelf: "end",
  },

  drawer: {
    display: "grid",
    alignContent: "end",
    "& span": {
      display: "grid",
      "& .MuiButton-root": {
        overflow: "hidden",
      },
    },
  },

  toolbar: {
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
    flexGrow: 1,
  },

  signUp: {
    color: "#0C344B",
    background: "white",
    fontSize: 14,
    borderRadius: 8,
    fontWeight: "bold",
    borderWidth: 2,
  },
  signIn: {
    fontSize: 16,
    margin: 10,
    color: "#0C344B",
  },
}));

export default navBarStyles;
