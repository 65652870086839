import {
  IS_FETCHING,
  SUBSCRIBE,
  GET_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION,
  ERROR,
  GET_PLAN_DETAIL,
  GET_SUBCRIPTION_ERROR,
  CANCEL_SUBSCRIPTION_ISFETCHING,
  CANCEL_SUBSCRIPTION_ERROR,
  UPDATE_PAYMENT_METHOD_ISFETCHING,
  UPDATE_PAYMENT_METHOD_ERROR,
  UPDATE_PAYMENT_METHOD,
} from "../types/payment.type";
import { firestore, functions } from "../../utils/Firebase";
import { getUserData } from "./auth.action";
import { Dispatch } from "redux";
import firebase from "firebase";
import { creditCard, Subscription } from "../../types/payment";

export const subscribe =
  (
    paymentMethodId: string,
    plan: string,
    user: firebase.User,
    phoneNumber: string,
    email: string,
    name: string
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: IS_FETCHING,
        payload: true,
      });
      await firestore
        .collection("users")
        .doc(user.uid)
        .collection("subscriptions")
        .doc("subscription")
        .set({
          subscribed: true,
          paymentMethodId: paymentMethodId,
          plan: plan,
          phone: phoneNumber,
          email: email,
          name: name,
        });

      const snapshot = await firestore
        .collection("users")
        .doc(user.uid)
        .collection("subscriptions")
        .doc("subscription")
        .get();

      dispatch({ type: SUBSCRIBE, payload: snapshot.data().subscribed });

      getUserData(user);
    } catch (error) {
      console.log(error);
      dispatch({
        type: ERROR,
        payload: "There was an error creating your subscription",
      });
    } finally {
      dispatch({
        type: IS_FETCHING,
        payload: false,
      });
    }
  };

export const getPlanDetails = (plan: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: ERROR,
      payload: null,
    });
    dispatch({
      type: IS_FETCHING,
      payload: true,
    });
    const request = functions.httpsCallable("getPlanDetails");
    const response = await request({ plan: plan });

    dispatch({
      type: GET_PLAN_DETAIL,
      payload: {
        currency: response.data["currency"],
        name: response.data["name"],
        interval: response.data["interval"],
        amount: response.data["amount"],
      },
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR,
      payload: "An error occured getting the plan details",
    });
  } finally {
    dispatch({
      type: IS_FETCHING,
      payload: false,
    });
  }
};

export const cancelSubscription =
  (user: firebase.User, subscriptionId: String) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: CANCEL_SUBSCRIPTION_ISFETCHING,
        payload: true,
      });
      const request = functions.httpsCallable("cancelStripeSubscription");
      await (
        await request({ id: subscriptionId })
      ).data;
      dispatch({
        type: CANCEL_SUBSCRIPTION,
      });
      getUserData(user);
    } catch (error) {
      console.log(error);
      dispatch({
        type: CANCEL_SUBSCRIPTION_ERROR,
        payload: error,
      });
    } finally {
      dispatch({
        type: CANCEL_SUBSCRIPTION_ISFETCHING,
        payload: false,
      });
    }
  };

export const updatePaymentMethod =
  (paymentMethod: String, subscriptionId: String) => async (dispatch: any) => {
    try {
      dispatch({
        type: UPDATE_PAYMENT_METHOD_ISFETCHING,
        payload: true,
      });

      const request = functions.httpsCallable("updateSubPaimentMethod");
      const response = await (
        await request({
          subscription: subscriptionId,
          creditCard: paymentMethod,
        })
      ).data;
      const newCreditCard: creditCard = {
        cardLastDigits: response["paymentMethodLastDigits"],
        cardBrand: response["cardBrand"],
        cardExpiration: response["cardExpiration"],
        name: response["creditCardName"],
      };
      dispatch({
        type: UPDATE_PAYMENT_METHOD,
        payload: newCreditCard,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_PAYMENT_METHOD_ERROR,
        payload: error,
      });
    } finally {
      dispatch({
        type: UPDATE_PAYMENT_METHOD_ISFETCHING,
        payload: false,
      });
    }
  };

export const getSubscription = () => async (dispatch: any) => {
  try {
    dispatch({
      type: IS_FETCHING,
      payload: true,
    });

    const request = functions.httpsCallable("getUserSubscriptionDetails");
    const subscriptionDetails = await (await request()).data;

    const subscriptionPayload: Subscription = {
      periodEnd: subscriptionDetails["subscriptionPeriodEnd"],
      periodStart: subscriptionDetails["subscriptionPeriodStart"],
      price: subscriptionDetails["price"],
      name: subscriptionDetails["planNickName"],
      billingInterval: subscriptionDetails["billingInterval"],
      id: subscriptionDetails["id"],
      credidCard: {
        cardLastDigits: subscriptionDetails["paymentMethodLastDigits"],
        cardBrand: subscriptionDetails["cardBrand"],
        cardExpiration: subscriptionDetails["cardExpiration"],
        name: subscriptionDetails["creditCardName"],
      },
    };
    dispatch({
      type: GET_SUBSCRIPTION,
      payload: subscriptionPayload,
    });
  } catch (e) {
    dispatch({
      type: GET_SUBCRIPTION_ERROR,
      payload: e,
    });
  } finally {
    dispatch({
      type: IS_FETCHING,
      payload: false,
    });
  }
};
