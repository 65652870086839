import React from "react";
import { makeStyles } from "@material-ui/core";

interface props {
  error: string;
}

const styles = makeStyles({
  root: {
    background: "red",
    borderRadius: 10,
    "& p": {
      color: "white",
    },
  },
});

const ErrorMessage = (props: props) => {
  const classes = styles();
  return (
    <div className={classes.root}>
      <p>{props.error}</p>
    </div>
  );
};

export default ErrorMessage;
