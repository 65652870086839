import {
  AUTH_ERROR,
  IS_FETCHING,
  SIGN_OUT,
  EDIT_EMAIL,
  GET_USER_DATA,
  FORGOT_PASSWORD,
  SIGN_IN_ERROR,
  SIGN_UP_SUCCESS,
} from "../types/auth.type";
import { purgeStoredState } from "redux-persist";
import { rootPersistConfig } from "../reducers";
import { persistor } from "..";
import { auth, firestore } from "../../utils/Firebase";
import firebase from "firebase";
import { Dispatch } from "redux";

const signOutLogic = async (dispatch: Dispatch) => {
  await auth.signOut();
  await purgeStoredState(rootPersistConfig);
  await persistor.purge();
  dispatch({
    type: SIGN_OUT,
  });
};

export const signIn =
  (email: string, password: string) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: IS_FETCHING,
        payload: true,
      });
      await auth.signInWithEmailAndPassword(email, password);
    } catch (e) {
      dispatch({
        type: SIGN_IN_ERROR,
        payload: e.message,
      });
    } finally {
      dispatch({
        type: IS_FETCHING,
        payload: false,
      });
    }
  };

export const signInWithToken =
  (token: string) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: IS_FETCHING,
        payload: true,
      });
      await auth.signInWithCustomToken(token);
    } catch (e) {
      dispatch({
        type: SIGN_IN_ERROR,
        payload: e.message,
      });
    } finally {
      dispatch({
        type: IS_FETCHING,
        payload: false,
      });
    }
  };

export const forgotPassword = (email: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: IS_FETCHING,
      payload: true,
    });

    await auth.sendPasswordResetEmail(email);

    dispatch({
      type: FORGOT_PASSWORD,
      payload: null,
    });
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
      payload: error,
    });
  } finally {
    dispatch({
      type: IS_FETCHING,
      payload: false,
    });
  }
};

export const facebookSignIn = () => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: IS_FETCHING,
      payload: true,
    });
    let provider = new firebase.auth.FacebookAuthProvider();
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

    const credentials = await auth.signInWithPopup(provider);
    getUserData(credentials.user);
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
      payload: error,
    });
  } finally {
    dispatch({
      type: IS_FETCHING,
      payload: false,
    });
  }
};

export const signup =
  (email: string, password: string, fullName: string) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: IS_FETCHING,
        payload: true,
      });
      const userCredentials = await auth.createUserWithEmailAndPassword(
        email,
        password
      );
      await userCredentials.user.updateProfile({
        displayName: fullName,
      });
      await auth.signInWithCredential(userCredentials.credential);
      dispatch({
        type: SIGN_UP_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: AUTH_ERROR,
        payload: "An error occured",
      });
    } finally {
      dispatch({
        type: IS_FETCHING,
        payload: false,
      });
    }
  };

export const signOut = () => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: IS_FETCHING,
      payload: true,
    });
    await signOutLogic(dispatch);
  } catch (e) {
    dispatch({
      type: AUTH_ERROR,
      payload: e,
    });
  } finally {
    dispatch({
      type: IS_FETCHING,
      payload: false,
    });
  }
};

export const getUserData =
  (user: firebase.User) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: IS_FETCHING,
        payload: true,
      });
      const snapshot = await firestore.doc(`/users/${user.uid}`).get();
      const data = snapshot.data();
      dispatch({
        type: GET_USER_DATA,
        payload: {
          rank: data.rank,
        },
      });
    } catch (error) {
      await signOutLogic(dispatch);
    } finally {
      dispatch({
        type: IS_FETCHING,
        payload: false,
      });
    }
  };

export const editEmail = (email: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: IS_FETCHING,
      payload: true,
    });
    const user = auth.currentUser;
    user.email = email;
    await user.updateEmail(email);
    await auth.updateCurrentUser(user);
    dispatch({
      type: EDIT_EMAIL,
      payload: auth.currentUser,
    });
  } catch (e) {
    dispatch({
      type: AUTH_ERROR,
      payload: e,
    });
  } finally {
    dispatch({
      type: IS_FETCHING,
      payload: false,
    });
  }
};

export const googleSignIn = () => async (dispatch: any) => {
  var provider = new firebase.auth.GoogleAuthProvider();
  try {
    dispatch({
      type: IS_FETCHING,
      payload: true,
    });
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    await auth.signInWithPopup(provider);
  } catch (e) {
    dispatch({
      type: AUTH_ERROR,
      payload: e,
    });
  } finally {
    dispatch({
      type: IS_FETCHING,
      payload: false,
    });
  }
};
