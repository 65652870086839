import React from "react";
import { IconButton } from "@material-ui/core";
import downloadStyle from "../styles/Download.style";
import { ReactComponent as PlayStoreIcon } from "../assets/playstore.svg";
import { ReactComponent as AppleIcon } from "../assets/apple.svg";
import { useTranslation } from "react-i18next";

export const Download = () => {
  const classes = downloadStyle();
  const { t } = useTranslation();
  return (
    <div className={classes.card} id="download">
      <h4>{t("Available on all these platforms")}</h4>
      <h5>{t("Download Best Report")}</h5>
      <div className={classes.root}>
        <span>
          <IconButton href="https://play.google.com/store/apps/details?id=com.smartapps.bestreport">
            <PlayStoreIcon className={classes.icon} />
          </IconButton>
          <p> PlayStore</p>
        </span>
        <span>
          <IconButton href="https://apps.apple.com/us/app/best-report/id1554741359">
            <AppleIcon className={classes.icon} />
          </IconButton>
          <span className={classes.appleDownload}>
            <p>AppStore</p>
          </span>
        </span>
      </div>
    </div>
  );
};
