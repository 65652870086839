import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import { persistStore } from "redux-persist";
import rootReducer from "./reducers";

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middleware = [thunk];
const store = createStore(rootReducer, compose(applyMiddleware(...middleware)));

const persistor = persistStore(store as any);

export { store, persistor };
