const fr = {
  translation: {
    About: "À propos",
    Pricing: "Prix",
    Download: "Télécharger",
    "My Account": "Mon compte",
    "Log in": "Se connecter",
    "Sign Up": "S'inscrire",
    "Save a considerable amount of time": "Gagnez un temps considérable",
    "Meet deadlines and reassure your customers":
      "Respectez les délais et rassurez vos clients",
    "Guarantee your sites conformity and quality":
      "Assurez la qualité de la conformité de vos chantiers",
    "Try Best Report": "Essayez Best Report",
    "The application of site monitoring for architects and masters work":
      "L'application de suivi des chantiers pour les architectes et les maîtres d'œuvre",
    "Available on all these platforms": "Disponible sur toutes ces plateformes",
    "Download Best Report": "Télécharger Best Report",
    "Coming soon ...": "Arrive bientôt ...",
    "Inspection and observation": "Inspection et observation",
    "observation-description":
      "Posez et relevez les réserves ou remarques en quelques secondes lors de vos réunions de chantier.",
    "Report and Communication": "Rapport et communication",
    "report-description":
      "Des rapports PDF détaillés garantissent que votre client et toutes les personnes concernées soient toujours à jour.",
    "organize-project": "Organisation et gestion",
    "organize-project-description":
      "Organisez votre projet en lots et sous-lots, centralisez tous vos documents et travaillez ensemble.",
    "precise-notes": "Prenez des notes précises et détaillées",
    "precise-notes-description":
      "Sur le chantier, avec votre téléphone ou votre tablette, prenez en quelques secondes vos remarques, observations ou réserves, enrichissez-les de photos, de dessins, de listes de tâches et assurez-vous qu'elles sont de la qualité et de la conformité de vos réalisations.",
    "Track your jobsites simply and intuitively":
      "Suivez vos chantiers simplement et intuitivement",
    "Reservations, remarks, reports, speakers, documents":
      "Réservations, remarques, rapports, orateurs, documents",
    Monthly: "Mensuel",
    Yearly: "Annuel",
    Trial: "Essai",
    "1 Worksite": "1 Chantier",
    uworksites: "Chantiers illimités",
    "Unlimted stakeholders": "Lots illimités",
    "Unlimited buildingtrades": "Corps de métier illimités",
    "Unlimited plans": "Plans illimités",
    "No report generation": "Pas de génération de rapport",
    "per month": "par mois",
    "Subscribe now": "Abonnez-vous maintenant",
    "Create an account": "Créer un compte",
    "Contact us for details": "Contactez-nous pour plus de détails",
    "Report generation": "Génération de rapport",
    "Custom report": "Rapport personnalisable",
    "24 h Support": "24 h Support",
    "Remote installation/configation": "Installation/configuration à distance",
    "Contact Sales": "Contactez nous",
    "Sign in": "S'identifier",
    "Sign up": "S'inscrire",
    Email: "Email",
    "Loading your order": "Chargement de votre achat ...",
    "This field is required": "Champs requis",
    "This is not a valid email": "Cet email n'est pas valide",
    "An error occured": "Une erreur est survenue.",
    Password: "Mot de passe",
    Create: "Créer",
    Name: "Nom",
    "Last name": "Prénom",
    sub: "Merci pour votre inscription !",
    "Please restart your app to get your license activated":
      "Veuillez redémarrer votre application pour activer votre licence",
    "Billing informations": "Informations de facturation",
    Phone: "Téléphone",
    "Payment information": "Informations de paiement",
    "Subscribe Now": "S'abonner maintenant",
    Summary: "Résumé",
    Total: "Total",
    Subscription: "Abonnement",
    "Cancel Subscription": "Annuler l'abonnement",
    "You have no current subscription": "Vous n'avez pas d'abonnement en cours",
    "Go home": "Revenir à l'acceuil",
    Account: "Mon compte",
    Disconnect: "Se déconnecter",
    "price-description":
      "Accès à toutes les fonctionnalités, nombre illimité de projets et un tarif sans surprise.",
    "Forgot password": "Mot de passe oublié",
    "per year": "Par année",
    January: "Janvier",
    February: "Février",
    March: "Mars",
    April: "Avril",
    May: "Mai",
    June: "Juin",
    July: "Juillet",
    August: "Aout",
    September: "Septembre",
    October: "Octobre",
    November: "Novembre",
    December: "Décembre",
    "Type of billing": "Type de prélèvement",
    "Next bill of": "Prochain prélèvement de",
    "will be debited the": "sera débité le",
    of: "",
    "Update payment method": "Modifier votre moyen de paiement",
    "allready-bought-BR": "J'ai deja acheté Best Reoirt ?",
    Update: "Mettre à jour",
    "30 days trial": "30 jours d'essai",
    "You are allready subscribed": "Vous ètes déja abonné",
  },
};

export default fr;
