import firebase from 'firebase';
import { store } from "../redux";
import { AUTH_STATE_CHANGE } from "../redux/types/auth.type";

const firebaseConfig = {
  apiKey: "AIzaSyBbFPvrw1ANASULFg5l5SsF8auiQKMXTSo",
  authDomain: "best-report-67891.firebaseapp.com",
  databaseURL: "https://best-report-67891.firebaseio.com",
  projectId: "best-report-67891",
  storageBucket: "best-report-67891.appspot.com",
  messagingSenderId: "69952989495",
  appId: "1:69952989495:web:0f5f9e0dfcf545b029d2b2",
  measurementId: "G-MTYMQ91YPW"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

const auth = firebase.auth();
const firestore = firebase.firestore();
const functions = firebase.functions();



auth.onAuthStateChanged((user) => {
  if (user) {
    store.dispatch({ type: AUTH_STATE_CHANGE, payload: true });
  } else {
    store.dispatch({ type: AUTH_STATE_CHANGE, payload: false });
  }
});

export { auth, firestore, functions };
