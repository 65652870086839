import { makeStyles } from "@material-ui/core";

const SignInFormStyle = makeStyles({
  form: {
    display: "grid",
    marginTop: 6,
    "& .MuiTextField-root": {
      marginTop: 6,
      background: "#EFF3F5",
    },
    maxWidth: 325,
  },

  submitbutton: {
    margin: 20,
    padding: 10,
    marginTop: 15,
    background: "#082333",
    color: "white",
  },

  icons: {
    marginTop: 30,
    display: "flex",
    justifyContent: "center",
    "& .MuiIconButton-root": {
      "& img": {
        height: 40,
      },
    },
  },

  forgotPassword: {
    color: "#2196f3",
    fontSize: 12,
    justifySelf: "left",
  },

  error: {
    color: "red",
    fontSize: 12,
    marginTop: 3,
  },
});

export default SignInFormStyle;
