import { makeStyles } from "@material-ui/core";

export const UserPageStyle = makeStyles({
  root: {
    height: "100%",
    alignContent: "center",
    justifyContent: "center",
    background: "#EFF3F5",
    display: "grid",
  },
  card: {
    display: "flex",
    borderRadius: 10,
    width: 600,
    minWidth: "10%",
  },

  signOut: {
    color: "red",
    justifySelf: "end",
  },

  goHome: {
    justifySelf: "left",
    alignSelf: "start",
    display: "inline-grid",
    left: 0,
    top: 0,
    position: "fixed",
    "& span": {
      display: "flex",
      alignContent: "center",
      alignItems: "center",
      justifyContent: "center",
      "& p": {
        color: "#1e88e5",
        marginLeft: 10,
      },
    },
  },

  profile: {
    marginLeft: 50,
    marginRight: 50,
    marginBottom: 30,
    justifyContent: "center",
    alignContent: "center",
    display: "grid",
    flexDirection: "column",
  },
  profileContainer: {
    width: "100%",
  },
  nameField: {
    textAlign: "center",
  },
  avatar: {
    background: "#1e88e5",
    height: 60,
    width: 60,
    marginTop: 30,
    justifySelf: "center",
    alignSelf: "center",
  },
  fieldTitle: {
    textAlign: "left",
  },
  passwordSpan: {
    textAlign: "left",
  },
  drawerSpan: {
    display: "flex",
    flexDirection: "row",
    margin: 5,
  },

  textFieldSpan: {
    display: "flex",
    "& .MuiIconButton-root": {
      color: "#1e88e5",
    },
    "& .MuiTextField-root": {},
  },

  errorMessage: {
    color: "red",
    margin: 3,
    fontSize: 13,
  },
  list: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  logOutButton: {
    color: "red",
    flexDirection: "column",
  },
});
