import React from "react";
import { pricingStyles, optionStyle } from "../styles/Pricing.style";
import {
  Grid,
  Card,
  Divider,
  Button,
  Tab,
  Tabs,
  CircularProgress,
} from "@material-ui/core";
import { optionProps } from "../types";
import { useSelector } from "react-redux";
import { state } from "../types/state";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { functions } from "../utils/Firebase";
import { useState } from "react";
import { useContext } from "react";
import { AuthContext } from "./AuthProvider";
const Pricing = () => {
  const classes = pricingStyles();
  const authContext = useContext(AuthContext);
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);
  const [isGeneratingCheckoutSession, setIsGeneratingCheckoutSession] =
    useState(false);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  const subscription = useSelector(
    (state: state) => state.paymentState.subscription
  );

  const rank = useSelector((state: state) => state.authState.rank);
  const history = useHistory();

  const Option = (props: optionProps) => {
    const classes = optionStyle(props);

    return (
      <span className={classes.option}>
        <span />
        <p>{props.text}</p>
      </span>
    );
  };

  return (
    <div className={classes.root} id="pricing">
      <p className={classes.mainTitle}>{t("Pricing")}</p>
      <p className={classes.subtitle}>{t("price-description")}</p>
      <Card className={classes.tabNavigator}>
        <Tabs value={value} onChange={handleChange} centered={true}>
          <Tab label={t("Monthly")} />
          <Tab label={t("Yearly")} />
        </Tabs>
      </Card>

      <Grid container className={classes.grid} spacing={6}>
        <Grid item xs className={classes.clasicOffer}>
          <Card>
            <p className={classes.title}>{t("Trial")}</p>
            <span className={classes.price}>
              <p className={classes.title}> 0 €</p>
            </span>
            <Divider />
            <Option text={t("1 Worksite")} color="#00cde4" />
            <Option text={t("30 days trial")} color="#00cde4" />
            <Option text={t("Unlimited buildingtrades")} color="#00cde4" />
            <Option text={t("Unlimited plans")} color="#00cde4" />
            <Option text={t("No report generation")} color="red" />

            <Button
              onClick={() => {
                history.push({
                  pathname: "/login",
                  state: { isSignUP: true },
                });
              }}
              disabled={rank > 0}
            >
              Try Now
            </Button>
          </Card>
        </Grid>
        <Grid item xs className={classes.clasicOffer}>
          <Card>
            <p className={classes.title}>Pro</p>
            {value === 0 && (
              <span className={classes.price}>
                <p>15 €</p>
                <p>{t("per month")}</p>
              </span>
            )}
            {value === 1 && (
              <span className={classes.price}>
                <p>120 €</p>
                <p>{t("per year")}</p>
              </span>
            )}
            <Divider />

            <Option text={t("uworksites")} color="#0f7ad8" />
            <Option text={t("Unlimted stakeholders")} color="#0f7ad8" />
            <Option text={t("Unlimited buildingtrades")} color="#0f7ad8" />
            <Option text={t("Unlimited plans")} color="#0f7ad8" />
            <Option text={t("Report generation")} color="#0f7ad8" />
            {authContext.authenticated ? (
              <Button
                disabled={
                  rank > 0 ||
                  isGeneratingCheckoutSession ||
                  subscription !== null
                }
                onClick={async () => {
                  setIsGeneratingCheckoutSession(true);
                  try {
                    const request = functions.httpsCallable(
                      "createCheckoutSession"
                    );
                    const checkoutUrl = await request({
                      domain: process.env.REACT_APP_DOMAIN,
                      plan:
                        value === 0
                          ? process.env.REACT_APP_PRO_MONTHLY_PLAN
                          : process.env.REACT_APP_PRO_YEARLY_PLAN,
                    });
                    if (checkoutUrl.data !== null) {
                      window.open(checkoutUrl.data, "_parent");
                    }
                  } catch (e) {}
                  setIsGeneratingCheckoutSession(false);
                }}
              >
                {isGeneratingCheckoutSession ? (
                  <CircularProgress />
                ) : subscription !== null ? (
                  t("You are allready subscribed")
                ) : (
                  t("Subscribe now")
                )}
              </Button>
            ) : (
              <Button
                onClick={() => {
                  history.push({
                    pathname: "/login",
                    state: { isSignUP: true },
                  });
                }}
              >
                {t("Create an account")}
              </Button>
            )}
          </Card>
        </Grid>
        <Grid item xs className={classes.customOffer}>
          <Card>
            <p className={classes.title}>Custom</p>
            <span className={classes.price}>
              <p>{t("Contact us for details")}</p>
            </span>
            <Divider />
            <Option text={t("uworksites")} color="green" />
            <Option text={t("Unlimted stakeholders")} color="green" />
            <Option text={t("Unlimited buildingtrades")} color="green" />
            <Option text={t("Unlimited plans")} color="green" />
            <Option text={t("Custom report")} color="green" />
            <Option text={t("24 h Support")} color="green" />
            <Option text={t("Remote installation/configation")} color="green" />
            <Button href="mailto:contact@bestreport.fr">
              {t("Contact Sales")}
            </Button>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};
export default Pricing;
