import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  makeStyles,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Cards from "react-credit-cards";
import ClearIcon from "@material-ui/icons/Clear";
import { state } from "../types/state";

import {
  getSubscription,
  cancelSubscription,
  updatePaymentMethod,
} from "../redux/actions/payments.action";
import { auth } from "../utils/Firebase";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Subscription } from "../types/payment";

export const SubscriptionDashboard = () => {
  const rank = useSelector((state: state) => state.authState.rank);
  const subscribed = useSelector((state: state) => state.authState.rank === 1);
  const [creditCardName, setCreditCardName] = useState("");
  const isCancelSub = useSelector(
    (state: state) => state.paymentState.cancelSubIsFetching
  );
  const isUpdatingPaymentMethod = useSelector(
    (state: state) => state.paymentState.updatePaymentMethodIsFetching
  );
  const classes = SubsriptionDashboardStyles();
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [cardError, setCardError] = useState("");
  const { t } = useTranslation();
  const monthNames = [
    t("January"),
    t("February"),
    t("March"),
    t("April"),
    t("May"),
    t("June"),
    t("July"),
    t("August"),
    t("September"),
    t("October"),
    t("November"),
    t("December"),
  ];
  const [user, setUser] = useState(null);

  const getDebitDate = (subscriptionInfo: Subscription): Date => {
    return new Date(subscriptionInfo.periodEnd * 1000);
  };
  const subscriptionInfo = useSelector(
    (state: state) => state.paymentState.subscription
  );
  const dispatch = useDispatch();
  const paymentIsFetching = useSelector(
    (state: state) => state.paymentState.isFetching
  );

  const getSubsctiptionIntervalUnit = (interval: string) => {
    switch (interval) {
      case "year":
        return t("Yearly");
      case "month":
        return t("Monthly");
      default:
        break;
    }
  };

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        dispatch(getSubscription());
        setLoading(false);
      }
    });
  }, [dispatch, rank, subscribed]);
  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: creditCardName,
        email: auth.currentUser.email,
      },
    });

    if (error) {
      console.log("[error]", error);
      setCardError(error.message);
    } else {
      console.log("[PaymentMethod]", paymentMethod);
    }
    if (paymentMethod != null) {
      dispatch(updatePaymentMethod(paymentMethod.id, subscriptionInfo.id));
      setShowDialog(false);
    }
    setCreditCardName("");
  };
  return (
    <div className={classes.subscriptions}>
      {paymentIsFetching || loading ? (
        <div className={classes.loadingDiv}>
          {" "}
          <CircularProgress />
        </div>
      ) : subscriptionInfo !== null ? (
        <div className={classes.subDiv}>
          <h4 className={classes.subscriptionTitle}>{t("Subscription")} :</h4>

          <Divider />
          <div className={classes.subscriptionInfoDiv}>
            <span className={classes.subInfoSpan}>
              <span className={classes.subscriptionTitleSpan}>
                <p className={classes.subscriptionName}>
                  {subscriptionInfo.name}
                </p>
                <Chip className={classes.subChip} label="activ" />
              </span>

              <span className={classes.subInfoDescriptionSpan}>
                <p>
                  {t("Type of billing")} :{" "}
                  {getSubsctiptionIntervalUnit(
                    subscriptionInfo.billingInterval
                  )}{" "}
                </p>
                <p>
                  {" "}
                  {t("Next bill of")}{" "}
                  {subscriptionInfo.price
                    .toString()
                    .substring(0, subscriptionInfo.price.toString().length - 2)}
                  € {t("will be debited the")}{" "}
                  {getDebitDate(subscriptionInfo).getDate()}
                  {" " + t("of") + " "}
                  {monthNames[getDebitDate(subscriptionInfo).getMonth()]}
                </p>
              </span>
            </span>
            <span style={{ alignSelf: "center" }}>
              {" "}
              {!isCancelSub ? (
                <IconButton
                  className={classes.cancelSubscriptionButton}
                  onClick={() => {
                    dispatch(cancelSubscription(user, subscriptionInfo.id));
                  }}
                >
                  {" "}
                  <ClearIcon style={{ color: "red", alignSelf: "center" }} />
                </IconButton>
              ) : (
                <CircularProgress style={{ scale: 0.7 }} />
              )}
            </span>
          </div>
          <Divider />
          <span className={classes.paiementMethodSpan}>
            <h4 className={classes.subscriptionTitle}>{t("Paiment")} :</h4>
            <Cards
              cvc={"000"}
              expiry={subscriptionInfo.credidCard.cardExpiration}
              name={subscriptionInfo.credidCard.name}
              issuer={subscriptionInfo.credidCard.cardBrand}
              number={
                "000000000000" + subscriptionInfo.credidCard.cardLastDigits
              }
            />
            <Button
              disabled={isUpdatingPaymentMethod}
              className={classes.changeCreditCardButton}
              onClick={() => {
                setShowDialog(true);
              }}
            >
              {isUpdatingPaymentMethod ? (
                <CircularProgress />
              ) : (
                t("Update payment method")
              )}
            </Button>
          </span>
        </div>
      ) : (
        <div className={classes.noSubDiv}>
          <p>{t("You have no current subscription")}</p>
          <Button
            color="primary"
            onClick={() => {
              dispatch(getSubscription());
            }}
          >
            {t("allready-bought-BR")}
          </Button>
        </div>
      )}

      <Dialog
        open={showDialog}
        onClose={() => {
          setCardError("");
          setShowDialog(false);
        }}
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">
          {t("Update payment method") + " :"}
        </DialogTitle>
        <DialogContent>
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: "16px",
                  color: "#424770",
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                },
                invalid: {
                  color: "#9e2146",
                },
              },
            }}
          />
          {cardError !== "" ? (
            <p className={classes.errorMsg}>{cardError}</p>
          ) : null}
          <Divider className={classes.creditCardFormDivider} />
          <span className={classes.dialogSpan}>
            <TextField
              size="small"
              label="Email"
              className={classes.cardEmailField}
              variant="outlined"
              inputProps={{ autoCorrect: "all" }}
              onChange={(event) => {
                setCreditCardName(event.target.value);
              }}
            />
            <Button variant="contained" onClick={handleSubmit} color="primary">
              {t("Update")}
            </Button>
          </span>
        </DialogContent>
      </Dialog>
    </div>
  );
};

let secondaryColor = "#697386;";

const SubsriptionDashboardStyles = makeStyles({
  subscriptionInfoDiv: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
  },
  subscriptions: {
    justifyContent: "center",
    display: "grid",
    padding: "50px",
    justifySelf: "center",
  },

  subscriptionName: {
    margin: 0,
    alignSelf: "start",
  },

  subDiv: {
    display: "grid",
  },

  subscriptionTitle: {
    justifySelf: "start",
    fontWeight: "bold",
    paddingBottom: 15,
    margin: 1,
  },

  subscriptionCard: {
    padding: 5,
    flexDirection: "row",
    display: "flex",
  },

  subInfoDescriptionSpan: {
    display: "flex",
    flexDirection: "column",
    alignContent: "start",

    "& p": {
      fontSize: 14,
      margin: 0,
      color: secondaryColor,
    },
  },

  noSubDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
  },

  subInfoSpan: {
    justifyContent: "start",
    display: "flex",
    alignContent: "start",
    flexDirection: "column",
    marginRight: 15,
  },
  creditCardFormDivider: {
    marginTop: 10,
    marginBottom: 10,
  },

  dialogSpan: {
    flexDirection: "row",
    flexWrap: "wrap",
    display: "flex",
    justifyContent: "space-between",
  },

  cardEmailField: {},

  errorMsg: {
    color: "red",
    fontSize: 14,
  },
  subscriptionTitleSpan: {
    display: "flex",
    flexDirection: "row",
    marginTop: 5,
    alignContent: "center",
  },
  subChip: {
    height: 25,
    marginLeft: 6,
    background: "#cbf4c9",
    color: "#217052",
  },
  loadingDiv: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
  },

  paiementMethodSpan: {
    marginTop: 20,
    display: "grid",
    alignContent: "start",
    justifyContent: "start",
  },

  cancelSubscriptionButton: {
    background: "#ffcccb",
    scale: 0.8,
    marginLeft: 10,
  },

  changeCreditCardButton: {
    color: "#29b6f6",
    width: 200,
    justifyContent: "start",
  },
});
