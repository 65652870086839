import mainBannerStyle from "../styles/MainBanner.style";
import React, { useContext } from "react";
import { Button, Hidden } from "@material-ui/core";
import WorksiteImg from "../assets/worksite.jpg";
import { CheckLine } from "./";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AuthContext } from "./AuthProvider";

export const MainBanner = () => {
  const authContext = useContext(AuthContext);
  const classes = mainBannerStyle();
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <span className={classes.mainTitle}>
        <h1>Best Report</h1>
        <p>
          {t(
            "The application of site monitoring for architects and masters work"
          )}
        </p>
        <CheckLine text={t("Save a considerable amount of time")} />
        <CheckLine text={t("Meet deadlines and reassure your customers")} />
        <CheckLine text={t("Guarantee your sites conformity and quality")} />
        {!authContext.authenticated ? (
          <Button
            className={classes.tryButton}
            onClick={() => {
              history.push({
                pathname: "/login/false",
              });
            }}
          >
            {t("Try Best Report")}
          </Button>
        ) : (
          <Button
            className={classes.tryButton}
            onClick={() => {
              document
                .getElementById("pricing")
                .scrollIntoView({ behavior: "smooth" });
            }}
          >
            {t("Subscribe now")}
          </Button>
        )}
      </span>
      <Hidden smDown={true}>
        <img src={WorksiteImg} alt="" className={classes.worksiteImg} />
      </Hidden>
    </div>
  );
};
