import { makeStyles } from "@material-ui/core";

const checkLineStyle = makeStyles({
  icon: {
    height: 24,
    width: 24,
    marginRight: 16,
    alignSelf: "center"
  },

  root: {
    flexDirection: "row",
    display: "flex",
    "& p": {
      fontSize: 16,
      color: "#0c344b"
    }
  }
});

export default checkLineStyle;
