import {
  IS_FETCHING,
  SIGN_OUT,
  EDIT_EMAIL,
  AUTH_ERROR,
  AUTH_STATE_CHANGE,
  GET_USER_DATA,
  FORGOT_PASSWORD,
  SIGN_IN_ERROR,
  SIGN_UP_SUCCESS,
} from "../types/auth.type";
import { authActionType } from "../../types";
import { state } from "../../types/state";

const initialState: state["authState"] = {
  error: null,
  isFetching: false,
  rank: 0,
  signInError: null,
  passwordReset: false,
};

const authReducer = (
  state = initialState,
  action: authActionType
): state["authState"] => {
  switch (action.type) {
    case AUTH_ERROR:
      return {
        ...state,
        error: action.payload.error,
        isFetching: false,
      };
    case GET_USER_DATA:
      return {
        ...state,
        rank: action.payload.rank,
      };
    case EDIT_EMAIL:
      return {
        ...state,
        user: action.payload,
      };
    case SIGN_IN_ERROR: {
      return {
        ...state,
        signInError: action.payload.errorMessage,
      };
    }
    case IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload,
        //Reseting the signInError when the user reSubmit a form
        signInError: action.payload ? null : state.signInError,
      };
    case SIGN_OUT:
      return {
        ...state,
      };
    case AUTH_STATE_CHANGE:
      return {
        ...state,
      };
    case FORGOT_PASSWORD:
      return {
        ...state,
        passwordReset: true,
      };
    case SIGN_UP_SUCCESS: {
      return { ...state };
    }
    default:
      return state;
  }
};

export default authReducer;
