import { makeStyles } from "@material-ui/core";
interface styleProps {
  diagonalHeight: number;
}

const homePageStyle = makeStyles({
  diagonalTop: {
    position: "absolute",
    transformOrigin: 0,
    transform: "skewY(-20deg)",
    width: "100%",
    height: "100%",
    background: "white",
    overflow: "hidden",
    zIndex: -1,
  },
  header: {
    position: "relative",
    zIndex: 0,
  },
  diagonaleBottom: {
    position: "absolute",
    transformOrigin: 100,
    transform: "skewY(-20deg)",
    width: "100%",
    height: "100%",
    background: "#082333",
    overflow: "hidden",
    zIndex: -1,
  },

  grids: {
    marginTop: 40,
  },

  screnShot1: {
    maxHeight: 500,
    borderRadius: 10,
    background:
      "rgba(0, 0, 0, 0) linear-gradient(rgba(239, 243, 245, 0) -10%, rgb(239, 243, 245) 95%) repeat scroll 0% 0%",
    inset: "50% 0px 0px",
  },

  footer: {},

  root: {
    background: "#EFF3F5",
    display: "block",
    position: "relative",
  },

  container: {
    zIndex: 3,
    maxWidth: 1200,
    marginRight: "auto",
    marginLeft: "auto",
    justifyContent: "center",
    alignContent: "center",
  },

  worksiteFollow: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: 80,
    "& span": {
      maxWidth: 500,
      "& h4": {
        color: "#082333",
        fontSize: 23,
        fontFamily: "Montserrat",
      },
    },
  },

  appAdvantage: {
    "& span": {
      display: "flex",
      alignItems: "center",
      "& p": {
        marginLeft: 10,
        color: "#082333",
        fontSize: 23,
      },
    },
    "& p": {
      fontSize: 16,
      fontFamily: "Montserrat",
    },
    marginTop: 120,
    flexDirection: "row",
    overflow: "hidden",
  },
});

export default homePageStyle;
