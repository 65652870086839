import "./utils/Firebase";

import React, { useEffect } from "react";
import HomePage from "./pages/Home.page";
import appStyle from "./styles/App.style";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PageNotFound from "./pages/404.page";
import LoginPage from "./pages/LogIn.page";
import { store, persistor } from "./redux";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { UserPage } from "./pages/User.page";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "./utils/i18n";
import { firestore, auth, functions } from "./utils/Firebase";
import { AuthProvider } from "./Components/AuthProvider";
import SubSucess from "./pages/SubSucess";

const stripe = loadStripe(process.env.REACT_APP_STRIPE_TOKEN);

const App = () => {
  const classes = appStyle();

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      console.log("Using firebase emulators");

      functions.useEmulator("localhost", 5001);
      auth.useEmulator("http://localhost:9099/auth");
      firestore.useEmulator("localhost", 8080);
    }

    document.body.style.height = "100%";
    document.documentElement.style.height = "100%";
    const element = document.getElementById("root");
    if (element != null) {
      element.style.height = "100%";
    }
  }, []);

  return (
    <Provider store={store}>
      <PersistGate
        loading={
          <Backdrop open>
            <CircularProgress color="inherit" />
          </Backdrop>
        }
        persistor={persistor}
      >
        <AuthProvider>
          <Elements stripe={stripe}>
            <BrowserRouter>
              <div className={classes.app}>
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={(props: any) => <HomePage {...props} />}
                  />
                  <Route
                    exact
                    path="/login/:signIn?/:redirect?"
                    component={LoginPage}
                  />
                  <Route exact path="/myaccount" render={() => <UserPage />} />
                  <Route
                    exact
                    path="/subscription/sucess"
                    render={() => <SubSucess />}
                  />
                  <Route path="*">
                    <PageNotFound />
                  </Route>
                </Switch>
              </div>
            </BrowserRouter>
          </Elements>
        </AuthProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
