const en = {
  translation: {
    About: "About",
    Pricing: "Pricing",
    Download: "Download",
    "My Account": "My Account",
    "Log in": "Log in",
    "Sign Up": "Sign Up",
    "Save a considerable amount of time": "Save a considerable amount of time",
    "Meet deadlines and reassure your customers":
      "Meet deadlines and reassure your customers",
    "Guarantee your sites conformity and quality":
      "Guarantee your sites conformity and quality",
    "Try Best Report": "Try Best Report",
    "The application of site monitoring for architects and masters work":
      "The application of site monitoring for architects and masters work.",
    "Available on all these platforms": "Available on all these platforms",
    "Download Best Report": "Download Best Reports",
    "Loading your order": "Loading your order ...",
    uworksites: "Unlimited worksites",
    "Coming soon ...": "Coming soon ...",
    sub: "Thanks for your subscription !",
    "Inspection and observation": " Inspection and observation",
    "observation-description":
      "Put down and lift up reserves or remarks in a few seconds during your worksite meetings.",
    "Report and Communication": "Report and Communication",
    "report-description":
      "Detailed PDF reports ensure that your customer andeveryone involved is always up to date.",
    "organize-project": "Organization and management",
    "organize-project-description":
      "Organize your project into batches and sub batches, centralize all your documents and work together.",
    "precise-notes": "Take precise and detailed notes",
    "precise-notes-description":
      "On the construction site, with your telephone or tablet, take your remarks, observations or reservations in a few seconds.Enrich them with pictures, drawings, task lists and make sure that they are of the quality and conformity of your achievements.",
    "Track your jobsites simply and intuitively":
      "Track your jobsites simply and intuitively",
    "Reservations, remarks, reports, speakers, documents":
      "Reservations, remarks, reports, speakers, documents",
    Monthly: "Monthly",
    Yearly: "Yearly",
    Trial: "Trial",
    "1 Worksite": "1 Worksite",
    "Unlimted stakeholders": "Unlimted stakeholders",
    "Unlimited buildingtrades": "Unlimited buildingtrades",
    "Unlimited plans": "Unlimited plans",
    "No report generation": "No report generation",
    "per month": "per month",
    "Subscribe now": "Subscribe now",
    "Create an account": "Create an account",
    "Contact us for details": "Contact us for details",
    "Report generation": "Report generation",
    "Custom report": "Custom report",
    "24 h Support": "24 h Support",
    "Remote installation/configation": "Remote installation/configation",
    "Contact Sales": "Contact Sales",
    "Sign in": "Sign in",
    "Sign up": "Sign up",
    Email: "Email",
    "This field is required": "This field is required",
    "This is not a valid email": "This is not a valid email",
    "An error occured": "An error occured",
    Password: "Password",
    Create: "Create",
    Name: "Name",
    "Last name": "Last name",
    "Thanks for your Subscription !": "Thanks for your subscrption !",
    "Please restart your app to get your license activated":
      "Please restart your app to get your license activated",
    "Billing informations": "Billing informations",
    Phone: "Phone",
    "Payment information": "Payment information",
    "Subscribe Now": "Subscribe Now",
    Summary: "Summary",
    Total: "Total",
    Subscription: "Subscription",
    "Cancel Subscription": "Cancel Subscription",
    "You have no current subscription": "You have no current subscription",
    "Go home": "Go home",
    Account: "Account",
    Disconnect: "Disconnect",
    "price-description":
      "Access to all features, unlimited number of projects and a no-surprise rate.",
    "Forgot password": "Forgot password",
    "per year": "per year",
    January: "January",
    February: "February",
    March: "March",
    April: "April",
    May: "May",
    June: "June",
    July: "July",
    August: "August",
    September: "September",
    October: "October",
    November: "November",
    December: "December",
    "Type of billing": "Type of billing",
    "Next bill of": "Next bill of",
    "will be debited the": "will be debited the",
    of: "of",
    "Update payment method": "Update payment method",
    "allready-bought-BR": "I allready bought Best Report ?",
    Update: "Update",
    "30 days trial": "30 days trial",
    "You are allready subscribed": "You are allready subscribed",
  },
};

export default en;
