import { makeStyles } from "@material-ui/core";

const appStyle = makeStyles({
  app: {
    fontFamily: "Open Sans",
    height: "100%",
  },
});

export default appStyle;
