import { makeStyles } from "@material-ui/core";

const downloadStyle = makeStyles({
  card: {
    marginTop: 50,
    padding: 10,
    justifyContent: "center",
    textAlign: "center",
    "& h4": {
      justifySelf: "center",
      fontFamily: "Montserrat",
      fontSize: 20,
    },
    "& h5": {
      color: "#324f95",
      fontSize: 16,
    },
  },
  icon: {
    height: 30,
    width: 30,
  },
  commingsoon: {
    alignSelf: "center",
    height: 20,
    color: "#0c344b",
    background: "#CCD9E9",
  },

  appleDownload: {
    justifyContent: "center",
    "& p": {
      margin: 3,
    },
  },

  root: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-evenly",
    "& span": {
      display: "flex",
      "& p": {
        fontSize: 15,
        color: "#7787a8",
        alignSelf: "center",
      },
    },
  },
});

export default downloadStyle;
