import { makeStyles } from "@material-ui/core";

const footerStyle = makeStyles({
  root: {
    background: "#082333",
    marginTop: 100,
    placeContent: "end space-between",
    flexDirection: "row",
    alignItems: "end",
    display: "flex",
    height: 120,
    bottom: 0,
    width: "100%",
    "& span": {
      padding: 30,

      "& p": {
        color: "#7787a8",
      },
      "& a": {
        color: "#7787a8",
      },
    },
  },
});

export default footerStyle;
