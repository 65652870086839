import React, { useContext, useEffect } from "react";
import { Tabs, Tab, Button } from "@material-ui/core";
import Icon from "../assets/squareIcon.png";
import { useHistory, RouteComponentProps } from "react-router-dom";
import LogInStyle from "../styles/LogIn.style";
import { SignInForm } from "../Components/forms";
import { SignUpForm } from "../Components/forms/SignUpForm";
import { useDispatch, useSelector } from "react-redux";
import { signIn, googleSignIn, facebookSignIn } from "../redux/actions/";
import { state } from "../types/state";
import { useTranslation } from "react-i18next";
import { auth, functions } from "../utils/Firebase";
import { AuthContext } from "../Components/AuthProvider";

type props = {
  signIn: string;
  redirect: string;
};

const LoginPage = (props: RouteComponentProps<props>) => {
  const classes = LogInStyle();
  let history = useHistory();
  const authContext = useContext(AuthContext);
  const dispatch = useDispatch();
  const isFetching = useSelector((state: state) => state.authState.isFetching);

  const signInOrSignUp = () => {
    let index: number;
    if (props.match.params.signIn === "false") {
      index = 1;
    } else {
      index = 0;
    }

    return index;
  };
  const { t } = useTranslation();

  const [value, setValue] = React.useState(signInOrSignUp());
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    async function fetchUrl() {
      if (authContext.authenticated) {
        try {
          if (
            props.match.params.redirect ===
              process.env.REACT_APP_PRO_YEARLY_PLAN ||
            props.match.params.redirect ===
              process.env.REACT_APP_PRO_MONTHLY_PLAN
          ) {
            console.log("checkout session");
            const request = functions.httpsCallable("createCheckoutSession");
            const url = await request({
              domain: process.env.REACT_APP_DOMAIN,
              plan: props.match.params.redirect,
            });

            if (url.data !== null) {
              window.open(url.data, "_parent");
            }
          } else if (props.match.params.redirect === "myaccount") {
            history.push("/myaccount");
          } else {
            history.push("/");
          }
        } catch (e) {
          history.push("/");
        }
      }
    }
    fetchUrl();
  }, [authContext.authenticated, history, props.match.params.redirect]);

  return (
    <div className={classes.root}>
      <Button
        className={classes.appIcon}
        onClick={() => {
          history.push("/");
        }}
      >
        <img src={Icon} className={classes.icon} alt="BestReport logo" />
      </Button>
      <Tabs
        value={value}
        onChange={handleChange}
        centered={true}
        className={classes.tabNavigator}
      >
        <Tab label={t("Sign in")} />
        <Tab label={t("Sign up")} />
      </Tabs>
      {value === 0 && (
        <SignInForm
          onSubmit={(email, password) => {
            dispatch(signIn(email, password));
          }}
          onGoogleSubmit={() => {
            dispatch(googleSignIn());
          }}
          onFacebookSubmit={() => {
            dispatch(facebookSignIn());
          }}
          isFetching={isFetching}
        />
      )}
      {value === 1 && <SignUpForm />}
    </div>
  );
};

export default LoginPage;
