import React from "react";
import footerStyle from "../styles/Footer.style";
const cgu = "../assets/cgu.pdf";

export const Footer = () => {
  const classes = footerStyle();

  return (
    <footer className={classes.root}>
      <span>
        <p>Copyright 2020</p>
      </span>
      <span>
        <a href={cgu} download>
          Mentions Légales / GGU
        </a>
      </span>
    </footer>
  );
};
