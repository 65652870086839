import { combineReducers } from "redux";
import authReducer from "./auth.reduces";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import paymentReducer from "./payment.reducer";

export const rootPersistConfig = {
  key: "root",
  storage: storage,
  blacklist: [] as any,
};

export const authPersistConfig = {
  key: "auth",
  storage: storage,
  blacklist: ["loggedIn", "error", "passwordReset", "isFetching"],
};

export const paymentPersistConfig = {
  key: "payment",
  storage: storage,
  blacklist: ["error"],
};

const rootReducer = persistReducer(
  rootPersistConfig,
  combineReducers({
    authState: persistReducer(authPersistConfig, authReducer),
    paymentState: persistReducer(paymentPersistConfig, paymentReducer),
  })
);

export default rootReducer;
